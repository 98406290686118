import { computed } from 'vue';
import { api } from '@/plugins/axios';
import { useZendeskStore } from '@/stores/zendesk';
import { useCustomerStore } from '@/stores/customer';
import { useUserStore } from '@/stores/user';

export function useZendeskTickets() {
  
  const zendeskStore = useZendeskStore();
  const customerStore = useCustomerStore();
  const userStore = useUserStore();

  const createTechTicket = async (data={}) => {
    if (!zendeskStore.getTicketId) throw "Sales Ticket Required"
    if (zendeskStore.getTechTicketId) {
      throw {
        link: `${zendeskStore.getTicketUrl(zendeskStore.getTechTicketId)}`,
        text: 'A tech ticket has already been created.',
      }
    }
    zendeskStore.setTechTicket(data);
    const requestBody = {
      zendesk_id: customerStore.getZendeskId,
      parent_ticket_id: zendeskStore.getTicketId,
      call_required: data.callRequired,
      issue: data.issue,
      callback_number: data.callbackNumber,
      timezone: data.timezone,
      availability: data.customerAvailability,
      pc_password: data.password,
      remote_connectivity: data.remoteConnectivity,
      customer_type: data.customerType,
      log_id: customerStore.getScreenPopId
    }
    const ticket = await api.post('/zendesk/tickets/tech', requestBody);
    zendeskStore.setTechTicketId(ticket.id)
    return ticket
  }

  const createPhoneTicket = async () => {
    let zendeskId = customerStore.getZendeskId;
    if (!zendeskId) {
      let { id } = await api.post('/zendesk/users', {
        name: customerStore.getName,
        email: customerStore.getEmail
      })
      customerStore.setZendeskId(id);
      zendeskId = id;
    }
    const ticket = await api.post('/zendesk/tickets', {
      subject: `Inbound call from ${customerStore.getANI || customerStore.getPhone}`,
      requester_id: zendeskId,
      comment: {
        public: false,
        html_body: `
          ${userStore.user.first_name} has taken the ticket<br><br>
          <b>Session ID:</b> ${customerStore.getCallSessionId}<br>
          <b>Caller ID:</b> ${customerStore.getANI || customerStore.getPhone}<br>
          <b>Dialed #:</b> ${customerStore.getDNIS}<br>
          <b>Queue:</b> ${customerStore.getQueue}
        `
      }
    })
    zendeskStore.setTicket(ticket);
    zendeskStore.setCreateTicket(false);
    return ticket;
  }

  const updatePaymentMethod = async ({order_id, product_id, schema_source='rd', subscription_dim_key}) => {

    if (!zendeskStore.getTicketId) throw 'No Zendesk Ticket Available';
    if (!order_id) order_id = customerStore.recentOrder
    let ticket = await api.post('/customers/payment_method', {
      ticket_id: zendeskStore.getTicketId, email: customerStore.getEmail,
      order_id, product_id, schema_source, subscription_dim_key,
      screen_pop_id: customerStore.getScreenPopId,
      call_session_id: customerStore.getCallSessionId
    })
    zendeskStore.setTicket(ticket);
    return ticket;
  }

  const applyZendeskTicketMacro = async ({ macro_id }) => {
    if (!zendeskStore.getTicketId) throw 'No Zendesk Ticket Available';
    let ticket = await api.post('/zendesk/apply_macro', {
      ticket_id: zendeskStore.getTicketId,
      macro_id
    })
    zendeskStore.setTicket(ticket)
    return ticket
  }

  const canTicketComment = computed(() => userStore.loggedIn && zendeskStore.getTicketId)

  const getTicketComments = next_page => {
    const ticketId = zendeskStore.getTicketId
    const query = next_page ? `?next_page=${next_page}` : ''
    return api.get(`/zendesk/tickets/${ticketId}/comments${query}`)
  }

  const createTicketComment = async ({ is_public, html }) => {
    const ticket_id = zendeskStore.getTicketId
    const ticket = await api.put(`/zendesk/tickets/${ticket_id}`, {
      is_public,
      html
    })
    zendeskStore.setTicket(ticket)
    return ticket
  }

  return {
    createTechTicket, createPhoneTicket, updatePaymentMethod, applyZendeskTicketMacro,
    canTicketComment, createTicketComment, getTicketComments
  }
}